<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-2">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
		    <el-table
			 :data="tree"
			 style="width: 100%;margin-bottom: 20px;"
			 row-key="id"
			 default-expand-all
			 :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
			 <el-table-column
			   prop="name"
			   label="名称"
			   align="left"
			   >
			   <template slot-scope="scope">
			   		<i class="el-icon-tickets mr-2" />
			   		<span>{{ scope.row.name }}</span>
			   </template>
			 </el-table-column>
			 <el-table-column label="封面"  align="center">
			   <template slot-scope="scope">
			 	<img :src="scope.row.url" style="width: 30px;height: 30px;" @click="viewImage(scope.row,'url')" v-if="scope.row.cover">
			 	<img src="@/assets/images/empty.png" style="width: 30px;height: 30px;" v-else>
			   </template>
			 </el-table-column>
			 <el-table-column label="排序"  align="center">
			   <template slot-scope="scope">
                   <span>{{ scope.row.sort }}</span>
			   </template>
			 </el-table-column>
			 <el-table-column label="推荐" align="center">
			   <template slot-scope="scope">
			 	<el-tag v-if="scope.row.is_rec === 1" size="mini" type="success">是</el-tag>
			 	<el-tag v-if="scope.row.is_rec === 2" size="mini" type="danger">否</el-tag>
			   </template>
			 </el-table-column>
			 <el-table-column label="操作" width="160" align="center">
			   <template slot-scope="scope">
				<el-tooltip class="item" effect="dark" content="添加" placement="top">
				  <el-button type="success" icon="el-icon-plus" size="mini" circle @click.stop="handleAdd(scope.row)"></el-button>		
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="修改" placement="top">
				  <el-button type="warning" icon="el-icon-edit" size="mini" circle @click.stop="handleEdit(scope.row)"></el-button>		
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="删除" placement="top">
				  <el-button type="danger" icon="el-icon-close" size="mini" circle @click.stop="handleDelete(scope.row.id)"></el-button>		
				</el-tooltip>
			   </template>
			 </el-table-column>
		   </el-table>
		</el-card>
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
			 <el-form-item label="上级" prop="pid">
				  <el-select v-model="form.pid" placeholder="请选择上级分类" size="medium" class="w-100">
					<el-option label="顶级分类" :value="0"></el-option>
					<el-option v-for="(item,index) in list" :key="index"
					  :label="item | tree"
					  :value="item.id">
					</el-option>
				  </el-select>
			</el-form-item>
		    <el-form-item label="名称" prop="name">
		      <el-input v-model="form.name" placeholder="请输入名称" type="text" clearable />
		    </el-form-item>
			<el-form-item label="介绍" prop="desc">
			  <el-input v-model="form.desc" placeholder="请输入介绍" type="text" clearable />
			</el-form-item>
			<el-form-item label="封面" prop="cover">
			  <div class="border rounded flex-all mr-3" style="width: 100px;height: 100px;line-height: 100px;cursor: pointer;" @click="chooseImage">
				<img :src="form.url" class="w-100 h-100" v-if="form.cover">
				<i class="el-icon-plus text-muted" style="font-size: 30px;" v-else></i>
			  </div>
			</el-form-item>
		    <el-form-item label="排序" prop="sort">
		      <el-input v-model="form.sort" placeholder="请输入排序" type="number" clearable />
		    </el-form-item>
			<el-form-item  label="推荐">
			   <el-radio-group v-model="form.is_rec">
			   	<el-radio :label="1">是</el-radio>
			   	<el-radio :label="2">否</el-radio>
			   </el-radio-group>
			 </el-form-item>
		   <el-form-item  label="状态">
		      <el-radio-group v-model="form.status">
		      	<el-radio :label="1">使用</el-radio>
		      	<el-radio :label="2">停用</el-radio>
		      </el-radio-group>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'goods_cat',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					pid:'',
					name:'',
					desc:'',
					sort:100,
					status:1,
					cover:'',
					url:'',
					is_rec:2,
				},
				rules:{
					name: [
					  { required: true, message: '请输入名称', trigger: 'blur' }
					],
				},
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				tree:[],
			}
		},
		mounted() {
			
		},
		methods:{
			getList() {
				this.loading = true
				this.axios.get(`/manage/${this.preUrl}/list`, {
				  params: this.queryForm
				}).then(res => {
				  this.total = res.total
				  this.list = res.data
				  this.tree = res.tree
				  this.loading = false
				}).catch(err => {
				  this.loading = false
				})
			},
			reset() {
			  this.form = {
				pid:'',
				name:'',
				desc:'',
				sort:100,
				status:1,
				cover:'',
				url:'',
				is_rec:2,
			  }
			  this.resetForm('form')
			},
			chooseImage(){
				this.app.chooseImage((res)=>{
				  this.form.cover = res[0].id
				  this.form.url = res[0].url
				})
			},
			handleAdd(e=false){
				if(!e){
					this.form = {
						pid:'',
						name:'',
						sort:100,
						status:1,
						cover:'',
						url:'',
					}
					this.editIndex = -1
				}else{
					this.form = {
						pid:e.id,
						name:'',
						status:1,
						sort:100,
						cover:'',
						url:'',
					}
					this.editIndex = -1
				}
				this.open = true
			},
		},
		filters: {
			tree(item) {
				if (item.level == 0) {
					return item.name
				}
				let str = ''
				for (let i = 0; i < item.level; i++) {
					str += i == 0 ? '|--' : '--'
				}
				return str + ' ' +item.name;
			}
		},
	}
</script>

<style>
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
.el-tree-node__content{
	padding: 20px 0;
}
</style>